import { InfoOutlineIcon } from "@chakra-ui/icons"
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, SimpleGrid, GridItem, Box, Text, HStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { organisation, organisationType } from "@aim/old_utils/organisations"
import { user } from "@aim/old_utils/utlity_users"
import IsBeta from "../helper/IsBeta"





export default (props:{onChange():void}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [currentOrg,setCurrentOrg] = useState<organisationType>()
    const [orgList,setOrgList] = useState<organisationType[]>([])
    useEffect(() => {
        if(!isOpen) return;
        organisation.getCurrent().then(setCurrentOrg)
        user.getAllLinkedOrgs().then(setOrgList)
    },[isOpen])


    const setOrganisation = async (newOrgID:string) => {
        const userObject = await user.getCurrent()
        await user.update(userObject.id,{
            currentOrganisation: newOrgID
        })
        props.onChange()
        onClose()
    }

    return (
        <IsBeta>
        <InfoOutlineIcon color='white' onClick={onOpen} />
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Organisatie menu</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <SimpleGrid column={1} >
                        {
                            orgList.map(org => (
                                <GridItem p={1}  bg='white' borderRadius={'xl'} >
                                    <Box onClick={() => setOrganisation(org.id) } borderRadius={'xl'} bg='gray.50' p={4} _hover={{background:'gray.100'}}  >
                                        <HStack>{currentOrg?.id==org.id && ( <Text color='brandBlue' fontSize='sm' >Actief - </Text> )}  <Text fontSize={'x-small'} >{org.id}</Text>  </HStack>
                                        
                                        <Text fontWeight={'bold'} >
                                            {org?.data?.name ?? '-'}
                                        </Text>
                                    </Box>
                                </GridItem>
                            ))
                        }
                        
                    </SimpleGrid>
                </ModalBody>

                <ModalFooter>

                </ModalFooter>
            </ModalContent>
         </Modal>
        </IsBeta>

    )
}
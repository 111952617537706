import { Flex, Text, Box, HStack, Center } from '@chakra-ui/react';
import { useRouter } from "next/router";
import { useState, useEffect } from 'react';
import Link from 'next/link'
import { setDeviceToken, setLastSeen, user, userType } from "@aim/old_utils/utlity_users";
import BrandIconButton from "./BrandIconButton";
import { auth, analytics } from '@aim/old_utils/config/firebase';
import { organisation, organisationType } from '@aim/old_utils/organisations';
import { Spinner } from '@chakra-ui/spinner';
import { motion } from 'framer-motion';
import { Device } from '@capacitor/device';
import {PushNotifications} from '@capacitor/push-notifications'
import { SplashScreen } from '@capacitor/splash-screen';
import { logEvent } from 'firebase/analytics';
// import { onAuthStateChanged } from 'firebase/auth';
import ProtectedPage from './layouts/ProtectedPage';
import OrgSelector from './aim/OrgSelector';




const data = [
    {href:'/',iconClassName: 'fi-rr-apps',name:'Dashboard'},
    {href:'/administration',iconClassName: 'fi-rr-bank',name:'Administratie'}
]



const NavMenu = ({height, show=true}:{
    height:string | number,
    show?:boolean

}) => {
    if(!show) return (<> </>)
    const router = useRouter()
    const isRouterPath = (href:string) => (router.pathname==href) ? true : false


    return (
        <Flex zIndex={100}  position='fixed' bottom='0px' width='100vw'  height={'75px'}  align='end' borderColor="gray.200"  >
            <Flex alignItems={'center'} borderTopLeftRadius={'xl'} borderTopRightRadius={'xl'} bg='white' boxShadow='base' w='full' justifyContent='space-around' h='full' height='75px' >

            {
                data.map((item,index) => {
                    const [hover, setHover] = useState<boolean>(false)
                    return(
                    <Link  key={`layout-${index}`} href={item.href}   >
                        <i  style={{ height:'24px', color: (isRouterPath(item.href) || hover) ? "#1631C7" : "#CECECE" } } className={item.iconClassName}></i>
                    </Link>
                )})
            }
            </Flex>
        </Flex>
    )}





function Layout(props :{
    nav?: boolean
    header?: boolean
    heading?:string
    children?:React.ReactNode
    height?:number
    isLoading?:boolean
}= {header:true}){

    const py = 4

    const router = useRouter()
    // const { user, useImpersonateUser } = useContext(UserContext)
    // const { organisation } = useContext(OrganisationContext)

    // const { impersonatedId, stopImpersonation } = useImpersonateUser()

    useEffect(() => {
        SplashScreen.hide()
    },[])





    useEffect(() => {
        setLastSeen()
        Device.getInfo().then( async(platform) => {
            if(platform.platform == 'web') {
                logEvent(analytics,`Page_${router.pathname}`, {
                    app_version:'web'
                });
            }else {
                logEvent(analytics,`Page_${router.pathname}`, { });
            }
            
            if(platform.platform == 'web') return;
            PushNotifications.requestPermissions()
                .then((permission) => {
                    console.log('premission',permission)
                    if (permission.receive == 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register();
                    } else {
                    // No permission for push granted
                    }})
            PushNotifications.addListener(
                'pushNotificationReceived',
                async (notification) => {
                    console.log('Push received: ' + JSON.stringify(notification));
                });
            PushNotifications.addListener(
                'registration',
                async (token) => {
                    const unsubscribe = auth.onAuthStateChanged(
                        async(user) => {
                        if(user){
                            console.log('there is a user')
                            const {id:orgID} = await organisation.getCurrent()
                            const tokenString = token.value
                            setDeviceToken(orgID,tokenString)
                            unsubscribe();
                        }
                     });
                })
        })


    }, [])


    const [userObject,setuserObject] = useState<userType>()
    const [organisationObject,setOrganisationObject] = useState<organisationType>()
    const handleOrgChange =  async () => {
        await user.getCurrent().then(object => setuserObject(object))
        await organisation.getCurrent().then(object => setOrganisationObject(object))
        
    }
    useEffect(() => {
        user.getCurrent().then(object => setuserObject(object))
        organisation.getCurrent().then(object => setOrganisationObject(object))
    } ,[])
    

    return (
        <>
        <ProtectedPage>
        { props.header || true && (
            <>
        <Box className='background layout_padding' mb={6}    >
            <Box  position='relative' className='background'  width='100vw'  height='full' >
                <Box position='absolute' left={0} width='50%' height='100%'  >

                <span style={{overflow:'hidden'}} className='background1' ></span>
                <span style={{overflow:'hidden'}} className='background2' ></span>
                <span style={{overflow:'hidden'}} className='background3' ></span>
                <span style={{overflow:'hidden'}} className='background4' ></span>
                <span style={{overflow:'hidden'}} className='background5' ></span>
                <span style={{overflow:'hidden'}} className='background6' ></span>
                <span style={{overflow:'hidden'}} className='background7' ></span>
                <span style={{overflow:'hidden'}} className='background8' ></span>
                <span style={{overflow:'hidden'}} className='background9' ></span>
                <span style={{overflow:'hidden'}}  className='background10' ></span>
                </Box>
            </Box>


            <Flex flexDirection='column' height={(router.pathname == '/') ? 175 : (props.height || 100)}  width='90%' position='relative'  mx='auto' my='auto' py='auto' alignItems='center' justifyContent='center'   >
                <Flex width='full' align='center' height={50}  >

                {
                    router.pathname !== '/' &&
                    <Flex zIndex={10}  height='100%' align='center'  justifyContent='center' >
                        <BrandIconButton onClick={() => router.push('.')} color='white' iconClassName="fi-rr-angle-left"  width={50}  height={50} />
                    </Flex>
                }


                <Box>
                    <Text color='white' fontWeight={'bold'} fontSize={'xx-small'} opacity={'0.5'} >{organisationObject?.data?.name ?? '-'}</Text>
                    <Text color='white' fontWeight={'bold'} opacity={'0.5'} >{props.heading}</Text>
                </Box>
                

                {/* RIGHT SIDE OF MENU */}
                <HStack marginLeft='auto' zIndex={10} spacing={2} >
                        <OrgSelector onChange={handleOrgChange} />
                        <Link data-cy='settings' href={`/settings`}>
                            <BrandIconButton  color='white'  width={25} height={25} iconClassName='fi-rr-settings' />
                        </Link>
                </HStack>
                </Flex>

                {router.pathname == '/' && <Flex  mx='auto' width='100%' color='white' flexDirection='column' >
                    <Text fontSize='xs' fontWeight='bold' opacity='0.5' color='white' >Welkom</Text>
                    <Text fontSize='x-large' fontWeight='bold' >{userObject?.data?.name}</Text>
                </Flex>}



            </Flex>
            <Box pt={router.pathname !== '/' ? 10 : 0}  borderTopRadius={10} position='relative'  style={{background: '#F3F4F6'}} minH='90vh'>
                {!props.isLoading
                ?
                <motion.main
                    variants={{
                        hidden: { opacity: 0, x: -200, y: 0 },
                        enter: { opacity: 1, x: 0, y: 0 },
                        exit: { opacity: 0, x: 0, y: -100 },
                    }} // Pass the variant object into Framer Motion 
                    initial="hidden" // Set the initial state to variants.hidden
                    animate="enter" // Animated state to variants.enter
                    exit="exit" // Exit state (used later) to variants.exit
                    transition={{ type: 'linear' }} // Set the transition to linear
                    className=""
                >
                    <Box width='90%' p={0} m={0} mx='auto' position='relative' >{props.children}</Box>
                </motion.main>
                : <Box width='90%' mx='auto' justifyItems='center' >
                    <Center>
                        <Spinner />
                    </Center>
                    </Box>
                }
                <HStack height={props.height || 50} mt={10}  ></HStack>     {/* NAVBAR HITBOX */}
            </Box>
            

        </Box>
        </>
        )
        }





        <NavMenu show={props.nav} height={props.height || 75} />
        </ProtectedPage>
        </>
)};


export default Layout;



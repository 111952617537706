import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Button, Center, Grid, HStack, Spinner } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"


export default () => {
    const router = useRouter()


    const [showBack,setShowBack] = useState<boolean>()
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBack(true)
        },500)
        return () => clearTimeout(timer)
    },[])



    return (
        <Grid templateRows={'20% 80%'} h='100vh' w='100vw' >
            <HStack w='full' m={5} >
                {
                    showBack &&
                    <Button onClick={() => router.back()} variant={'ghost'} w={10} h={10} >
                        <ChevronLeftIcon />
                    </Button>
                }
            </HStack>
            <Center  >
                <Spinner />
            </Center>
        </Grid>
    )
}
import { useState } from "react"

type IconTypes = 
    |   "sign-out"
    |   "add"
    |   'alarm-clock'
    |   'align-center'
    |   'align-justify'
    |   'align-left'
    |   'align-right'
    |   'ambulance'
    |   'angle-double-left'
    |   'angle-double-right'
    |   'angle-double-small-left'
    |   'angle-double-small-right'
    |   'angle-down'
    |   'angle-left'
    |   'angle-right'
    |   'angle-small-down'
    |   'angle-small-left'
    |   'angle-small-right'
    |   'angle-small-up'
    |   'angle-up'
    |   'apps'
    |   'apps-add'
    |   'apps-delete'
    |   'apps-sort'
    |   'archive'
    |   'arrow-down'
    |   'arrow-left'
    |   'arrow-right'
    |   'arrow-up'
    |   'arrow-down'
    |   'arrow-small-down'
    |   'arrow-small-left'
    |   'arrow-small-right'
    |   'arrow-small-up'
    |   'arrow-small-down'
    |   'asterik'
    |   'document-signed'
    |   string


export default (props:{
    width?:string | number,
    height?:string | number,
    iconClassName: IconTypes,
    color?:string
    onClick?():void
    }) => {
    const style = {
        color: props?.color ?? 'black',
        display: 'inline-flex',
        width: (props?.width) ? props.width : '100%',
        height: (props?.height) ? props.height : '100%',
        'justifyContent': 'center',
        'alignItems': 'center',
        opacity: 0.7,
    }
    const _hover = {
        color: props?.color ?? 'black',
        display: 'inline-flex',
        width: (props?.width) ? props.width : '100%',
        height: (props?.height) ? props.height : '100%',
        'justifyContent': 'center',
        'alignItems': 'center',
        opacity: 1,
    }

    const [hoverState,setHoverState] = useState(false)
    const className = () => {
        if(props.iconClassName.includes('fi-sr-')) return props.iconClassName
        if(props.iconClassName.includes('fi-rr-')) return props.iconClassName
        return `fi-rr-${props.iconClassName}`
    }

    return ( <i onClick={props.onClick} onMouseLeave={() => setHoverState(false)} onMouseOver={() => setHoverState(true)} style={(hoverState) ? _hover : style} className={className()} /> )
}

import { onAuthStateChanged } from "firebase/auth"
import { AnimatePresence, motion } from "framer-motion"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { auth } from "@aim/old_utils/config/firebase"
import { notification } from "@aim/old_utils/utlity_notification"
import LoadingDataPage from "../aim/LoadingDataPage"


const publicPaths = [
    '/user/login',
    '/user/password_reset',
    '/links/action_url',
    'action_url',
    'download',
    '/user/register',
    '/user/settings',
    '/user/logout',
    '/user/subscription',
    'appDownload',
    '/card',
    '/card/public',
    '/card/create',
    '/settings/subscription',
    '/checkout/aimcard',
    '/invoice/payment'
]


export default ({children}:{children: React.ReactNode}) => {
    const router = useRouter()


    const [isLoadingAuth, setisLoadingAuth] = useState<boolean>(true)
    const checkRoute = () => {

        
        const unSubscribe = onAuthStateChanged(
            auth,
            async (userData) => {
                console.log('router.pathname',router.pathname)
                if(userData==null && !publicPaths.includes(router.pathname)  ) {
                    notification.warning('Pagina is niet openbaar')
                    return router.push('/user/login')
                }
                if(userData==null) return
                // const currentUserData = (await user.getCurrent()).data
                // if(currentUserData?.currentOrganisation==null) router.push('/setup/organisation')
                setisLoadingAuth(false)
            }
            )
        return unSubscribe
    }


    useEffect(() => {
        const unSub = checkRoute()
        return () => unSub()
    } ,[])


    return (
        <>
        <AnimatePresence>
            {isLoadingAuth ?
            <motion.div
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
            >
                <LoadingDataPage />
            </motion.div>
            :
            <motion.div
                initial={{opacity:0}}
                animate={{opacity:1}}
                exit={{opacity:0}}
            >
                {children}
            </motion.div>
            }
        
        </AnimatePresence>
        </>
    )



}
import { Box } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { user } from "@aim/old_utils/utlity_users"


export default ({children}:{children:React.ReactNode}) => {


    const [show,setShow] = useState<boolean>(false)
    useEffect(() => {
        user.getCurrent()
            .then(userObject => {
                if(userObject?.data?.roles?.isBeta) setShow(true)
            })
    },[])

    if(!show) return <> </>;
    return (
        <Box>
            {children}
        </Box>
    )
}